.Respuestas form {
    padding-bottom: 15px;
    margin: 0 auto 100px;
}

.Respuestas form select {
    height: 38px;
}

.button-orange[disabled]:hover {
    background-color: #E17B34;
    color: #fff;
}

.button-orange {
    background-color: #E17B34;
    color: #fff;  
}

.button-orange:hover {
    background-color: #E7E7E7;
    color: #000;
}

.col-sm-2 {
    padding-left: 0px;
    padding-top: 4px;
}

.inline-form-group {
    height: 30px;
}