@media all and (min-width: 480px) {
    .ResetPassword {
        padding: 60px;
    }

    .ResetPassword form {
        margin: 0 auto 100px;
        max-width: 320px;
    }

    .ResetPassword .success {
        max-width: 400px;
    }

    .button-orange[disabled]:hover {
        background-color: #E17B34;
        color: #fff;
    }

    .button-orange {
        background-color: #E17B34;
        color: #fff;
    }

    .button-orange:hover {
        background-color: #E7E7E7;
        color: #000;
    }
}

.ResetPassword .success {
    margin: 0 auto;
    text-align: center;
}

.ResetPassword .sucess .plyphicon {
    color: grey;
    font-size: 30px;
    margin-bottom: 30px;
}