.Certificado {
    margin: 0 auto 100px;
}

.Certificado h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Certificado select {
    height: 38px;
}

.center-text {
    text-align: center;
    cursor: pointer;
    color: #E17B34;
}