.NivelMadurez form {
    padding-bottom: 15px;
    margin: 0 auto 100px;
}

.NivelMadurez form textarea {
    height: 300px;
    font-size: 18px;
}

.NivelMadurez form select {
    height: 38px;
}

/* Chrome, Safari, Edge, Opera */
.NivelMadurez form input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.NivelMadurez form input[type=number] {
    -moz-appearance: textfield;
}

.button-orange[disabled]:hover {
    background-color: #E17B34;
    color: #fff;
}

.button-orange {
    background-color: #E17B34;
    color: #fff;
}

.button-orange:hover {
    background-color: #E7E7E7;
    color: #000;
}