@media all and (min-width: 480) {
    .ChangePassword {
        padding: 60px 0;
    }

    .ChangePassword form {
        margin: 0 auto 100px;
        max-width: 320px
    }

    .button-orange[disabled]:hover {
        background-color: #E17B34;
        color: #fff;
    }

    .button-orange {
        background-color: #E17B34;
        color: #fff;
    }

    .button-orange:hover {
        background-color: #E7E7E7;
        color: #000;
    }
}