.NewTipoEvaluacion form {
    padding-bottom: 15px;
}

.NewTipoEvaluacion form textarea {
    height: 300px;
    font-size: 18px;
}

.button-orange[disabled]:hover {
    background-color: #E17B34;
    color: #fff;
}

.button-orange {
    background-color: #E17B34;
    color: #fff;
}

.button-orange:hover {
    background-color: #E7E7E7;
    color: #000;
}