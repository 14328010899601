h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #E17B34;
}

p {
    text-align: justify;
}

.custom-modal {
    width: 65%;
}