.AsignarDescuento form {
    padding-bottom: 15px; 
    margin: 0 auto 100px;
}

/* Chrome, Safari, Edge, Opera */
.AsignarDescuento form input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.AsignarDescuento form input[type=number] {
    -moz-appearance: textfield;
}


.button-orange[disabled]:hover {
    background-color: #E17B34;
    color: #fff;
}

.button-orange {
    background-color: #E17B34;
    color: #fff;
}

.button-orange:hover {
    background-color: #E7E7E7;
    color: #000;
}