.BillingForm .card-field {
    margin-bottom: 15px;
    background-color: white;
    padding: 11px 16px;
    border-radius: 6px;
    border: 1px solid #CCC;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .75);
    line-height: 1.3333333;
}

.BillingForm .card-field.StripeElement--focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .75), 0 0 8px rgba(102, 175, 233, .6);
    border-color: #66AFE9;
}