@media all and (min-width: 480px) {
    .Signup {
        padding: 5px 0;
    }

    .Signup form {
        margin: 0 auto 100px;
        max-width: 370px;
    }

    .Signup form select {
        height: 38px;
    }

    .Signup form span.help-block {
        font-size: 14px;
        padding-bottom: 10px;
        /* color: #999; */
    }

    .Signup form button[disabled]:hover {
        background-color: #E17B34;
        color: #fff;
    }

    .button-orange {
        background-color: #E17B34;
        color: #fff;
    }

    .button-orange:hover {
        background-color: #E7E7E7;
        color: #000;
    }
}


.Signup form {
        margin: 0 auto 100px;
        max-width: 370px;
}