.NivelAcuerdo form {
    padding-bottom: 15px;
}

.NivelAcuerdo form textarea {
    height: 300px;
    font-size: 18px;
}

/* Chrome, Safari, Edge, Opera */
.NivelAcuerdo form input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.NivelAcuerdo form input[type=number] {
    -moz-appearance: textfield;
}