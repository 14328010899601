.Pago form {
    padding-bottom: 15px;
    margin: 0 auto 100px;
}

h1 {
    color: #E17B34;
}

.button-orange[disabled]:hover {
    background-color: #E17B34;
    color: #fff;
}

.button-orange {
    background-color: #E17B34;
    color: #fff;
}

.button-orange:hover {
    background-color: #E7E7E7;
    color: #000;
}

.col-sm-5 {
    padding-left: 0px;
}

.button-send {
    display: none;
}