.ResolucionProblema form {
  padding-bottom: 15px;
  margin: 0 auto 100px;
}

.ResolucionProblema form textarea {
  height: 300px;
  font-size: 18px;
}

.ResolucionProblema form select {
  height: 38px;
}

.radio-large label input {
  width: 20px;
  height: 20px;
}

.radio-large {
  text-align: center;
}

.header-center {
  text-align: center;
}

.button-orange[disabled]:hover {
  background-color: #e17b34;
  color: #fff;
}

.button-orange {
  background-color: #e17b34;
  color: #fff;
}

.button-orange:hover {
  background-color: #e7e7e7;
  color: #000;
}
