.App {
  /* text-align: center; */
  margin-top: 15px;
  padding-right: 15px;
}

.App .navbar-brand {
  font-weight: bold;
  height: 80px;
}

.navbar {
  font-weight: bold;
}

/* Para centrar los items del menu*/
.navbar-nav {
  padding: 12px 0;
}

/* Color por default de los items del menu principal */
.navbar .navbar-nav li a, .navbar .navbar-nav li a:focus {
  color: #777;
}

/* Mouse hover naranja */
.navbar .navbar-nav li a:hover {
  color: #E17B34;
}

/* Propiedad active corresponde a un  menu-item actualmente seleccionado */
.dropdown-menu .active a {
  background-color: #E7E7E7;  
}

/* Menu-item actualmente en pantalla */
.dropdown-menu .active a:hover {
  background-color: #E7E7E7;
}

/* Menu-item deshabilitado */
.navbar .navbar-nav li .disabled a {
  background-color: #777;
  color: #fff;
}




.footer{
  width:100%;
  height: 50px;
  position: fixed;
  bottom:0;
  left:0;
  background-color: #242424;
  margin: auto;
  text-align: center;
  color: #fff;
  font-size: 13px;
  line-height: 50px;
  z-index: 100;
}

.footer p {
  margin: 0;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.header {
  width:100%;
  height:20%;
  position:absolute;
  top:0;
  left:0;
  background-color:blue;
}


/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*/