.Productos {
    margin: 0 auto 100px;
}

.Productos h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Productos p {
    color: #666;
}

h1 {
    color: #E17B34;
}