.ImprimirCertificado {
    margin: 0 auto 100px;
}

.ImprimirCertificado iframe {
    height: 900px;
    width: 100%;
}


h1 {
    color: #E17B34;
}