.PersonaLibro form {
  padding-bottom: 15px;
  margin: 0 auto 100px;
}

.PersonaLibro form select {
  height: 38px;
}

.button-orange[disabled]:hover {
  background-color: #e17b34;
  color: #fff;
}

.button-orange {
  background-color: #e17b34;
  color: #fff;
}

.button-orange:hover {
  background-color: #e7e7e7;
  color: #000;
}

.col-sm-2 {
  padding-left: 0px;
  padding-top: 4px;
}

.inline-form-group {
  height: 30px;
}
