.PerfilPM form {
    padding-bottom: 15px;
    margin: 0 auto 100px;
}

.PerfilPM form textarea {
    height: 300px;
    font-size: 18px;
}

.PerfilPM form select {
    height: 38px;
}

.checkbox-large label input {
    width: 20px; 
    height: 20px;
}

.button-orange[disabled]:hover {
    background-color: #E17B34;
    color: #fff;
}

.button-orange {
    background-color: #E17B34;
    color: #fff;
}

.button-orange:hover {
    background-color: #E7E7E7;
    color: #000;
}

h1 {
    color: #E17B34;
}

.table thead tr th {
    color: #E17B34;
}

.table tbody tr td, .table thead tr th {
    border-color: #E17B34;
}