.EstiloLiderazgoTareaPersona form {
  padding-bottom: 15px;
  margin: 0 auto 100px;
}

.EstiloLiderazgoTareaPersona form textarea {
  height: 300px;
  font-size: 18px;
}

.EstiloLiderazgoTareaPersona form select {
  height: 38px;
}

.checkbox-large label input {
  width: 20px;
  height: 20px;
}

.button-orange[disabled]:hover {
  background-color: #e17b34;
  color: #fff;
}

.button-orange {
  background-color: #e17b34;
  color: #fff;
}

.button-orange:hover {
  background-color: #e7e7e7;
  color: #000;
}

h1 {
  color: #e17b34;
}

.table thead tr th {
  color: #e17b34;
}

.table tbody tr td,
.table thead tr th {
  border-color: #e17b34;
}
