.SaludProyecto form {
    padding-bottom: 15px;
    margin: 0 auto 100px;
}

.SaludProyecto form textarea {
    height: 300px;
    font-size: 18px;
}

.SaludProyecto form select {
    height: 38px;
}

.radio-large label input {
    width: 20px; 
    height: 20px;
}

.radio-large {
    text-align: center;
}

.header-center {
    text-align: center;
}

.button-orange[disabled]:hover {
    background-color: #E17B34;
    color: #fff;
}

.button-orange {
    background-color: #E17B34;
    color: #fff;
}

.button-orange:hover {
    background-color: #E7E7E7;
    color: #000;
}